import React, { useState, useEffect } from 'react'
import { requestToApi } from 'react-data-fetching'
import * as Settings from './Settings'

import { message } from 'antd'
import Position from './Position'
import AddWeekliesSymbolsBar from './Watchlist/AddWeekliesSymbolsBar'
import AddSymbolBar from './Watchlist/AddSymbolBar'

// class Watchlist extends Component {
const Watchlist = ({
  locked,
  listName,
  showWatchlist,
  showWeeklies,
  relativeSymbol,
  presetSymbols,
  updateListAfterAdd
}) => {
  const [positions, setPositions] = useState([])

  useEffect(() => {
    if (presetSymbols && presetSymbols.length > 0) {
      const data = presetSymbols.map(s => {
        return { symbol: s }
      })
      updateSymbolStateFromResponse(data)
    } else {
      loadSymbols()
    }
  }, [])

  const getSafeListName = () => {
    return listName.toLowerCase().replace(' ', '_')
  }

  const loadSymbols = async () => {
    const response = await requestToApi({
      url: `${Settings.API_BASE_URL}lists/${getSafeListName()}`,
      method: 'GET'
    })

    if (response.isOK) {
      updateSymbolStateFromResponse(response.data.results)
    }
  }

  const updateSymbolStateFromResponse = results => {
    setPositions(
      results.map(result => ({
        ticker: result.symbol
      }))
    )
  }

  const onAddSymbolsToCurrentList = async (symbols, replace = false) => {
    return onAddSymbolsToList(symbols, getSafeListName(), replace)
  }

  const onAddSymbolToWatchlist = async symbol => {
    return onAddSymbolsToList([symbol], 'watchlist')
  }

  const onAddSymbolsToList = async (symbols, listName, replace = false) => {
    const response = await requestToApi({
      url: `${Settings.API_BASE_URL}lists/${listName}`,
      method: 'POST',
      body: {
        symbols: symbols,
        is_replacing: replace
      }
    })

    if (response.isOK) {
      if (updateListAfterAdd) {
        updateSymbolStateFromResponse(response.data.results)
      }
      message.info('Symbols added.')
    }
  }

  const onRemoveSymbol = async symbol => {
    const response = await requestToApi({
      url: `${Settings.API_BASE_URL}lists/${getSafeListName()}/${symbol}`,
      method: 'DELETE'
    })

    if (response.isOK) {
      updateSymbolStateFromResponse(response.data.results)
      message.info('Symbols removed.')
    }
  }

  return (
    <div className='content'>
      {showWatchlist && (
        <div style={{ textAlign: 'right', float: 'right', marginRight: 15 }}>
          <AddSymbolBar onAddSymbols={onAddSymbolsToCurrentList} />
        </div>
      )}
      {showWeeklies && (
        <div style={{ textAlign: 'right', float: 'right', marginRight: 15 }}>
          <AddWeekliesSymbolsBar onAddSymbols={onAddSymbolsToCurrentList} />
        </div>
      )}
      <h1>{listName}</h1>
      {positions ? (
        <div style={{ float: 'right', marginRight: 15 }}>
          {positions.map(position => (
            <Position
              key={position.ticker}
              ticker={position.ticker}
              relativeSymbol={relativeSymbol}
              onDelete={locked || presetSymbols ? null : onRemoveSymbol}
              onAddToWatchlist={
                locked && !presetSymbols ? null : onAddSymbolToWatchlist
              }
            />
          ))}
        </div>
      ) : (
        <h3>List is empty.</h3>
      )}
    </div>
  )
}

export default Watchlist
