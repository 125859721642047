import moment from 'moment'

// Mainly for dealing with options
export const parsePositionData = positionData => {
  var symbolStr = positionData.Symbol || ''
  symbolStr = symbolStr.replace(/\s+/g, ' ')
  const parts = symbolStr.split(' ')

  const data = {
    symbol: parts[0]
  }

  let opt, exp, year, month, day
  if (parts.length > 1) {
    opt = parts[1].split('C')

    if (opt.length > 1) {
      data.type = 'C'
      data.strike = parseInt(opt[1]) / 1000
      exp = opt[0]
      year = parseInt('20' + exp.substring(0, 2))
      month = parseInt(exp.substring(2, 4))
      day = parseInt(exp.substring(4, 6))

      data.exp = new Date(year, month - 1, day, 13, 0)
    } else {
      opt = parts[1].split('P')
      if (opt.length > 1) {
        data.type = 'P'
        data.strike = parseInt(opt[1]) / 1000
        exp = opt[0]
        year = parseInt('20' + exp.substring(0, 2))
        month = parseInt(exp.substring(2, 4))
        day = parseInt(exp.substring(4, 6))

        data.exp = new Date(year, month - 1, day, 13, 0)
      }
    }
  }

  data.quantity = positionData.Quantity
  return data
}

const displayVertical = (type, positions, maxGain, maxLoss) => {
  const pos1 = positions[0]
  const pos2 = positions[1]
  return {
    text: `${type} ${pos1.strike}-${pos2.strike}`,
    expiration: pos1.exp,
    type: maxGain > maxLoss ? 'vertical-bull' : 'vertical-bear',
    maxGain,
    maxLoss
  }
}

const displayButterfly = (type, positions, maxGain, maxLosses) => {
  return {
    text: `${type} ${positions[0].strike}-${positions[1].strike}-${positions[2].strike}`,
    expiration: positions[0].exp,
    type: 'butterfly',
    maxGain,
    maxLosses
  }
}

const displayStraightOption = (type, position) => {
  return {
    text: `${type} ${position.strike}`,
    expiration: position.exp,
    type:
      position.type === 'C' ? 'call' : position.type === 'P' ? 'put' : 'unknown'
  }
}

const displayLongStock = (str, position) => {
  return {
    text: str,
    expiration: null,
    type: 'stock-long'
  }
}

const displayShortStock = (str, position) => {
  return {
    text: str,
    expiration: null,
    type: 'stock-short'
  }
}

const displayPlain = (str, positions) => {
  const pos = positions ? positions[0] : null
  return {
    text: str,
    expiration: pos ? pos.exp : null,
    type: 'unknown'
  }
}

export const getStrategyText = positions => {
  // first check if positions are all options
  var allOptions = true
  positions.forEach(function (position) {
    var parts = position.Symbol?.split(' ') // TODO centralize this
    if (parts && parts.length === 1) {
      allOptions = false
    }
  })

  if (!allOptions) {
    if (positions.length === 1) {
      var qty = positions[0].Quantity
      if (qty > 0) {
        return displayLongStock('Long Stock', positions[0])
      } else {
        return displayShortStock('Short Stock', positions[0])
      }
    } else {
      return displayPlain('Mixed Stock', positions)
    }
  } else {
    var parsedPos = positions.map(p => parsePositionData(p))
    // Sort by higher strike first, and most recent expiration
    parsedPos.sort((a, b) => {
      return a.strike < b.strike
        ? 1
        : a.strike > b.strike
        ? -1
        : a.exp > b.exp
        ? 1
        : -1
    })

    if (positions.length === 1) {
      var data = parsedPos[0]
      if (data.type === 'C') {
        return displayStraightOption('Call ', data)
      } else if (data.type === 'P') {
        return displayStraightOption('Put ', data)
      } else {
        return displayPlain('Unsupported', [data])
      }
    } else if (positions.length === 2) {
      var pos1 = parsedPos[0]
      var pos2 = parsedPos[1]

      if (pos1.exp.getTime() === pos2.exp.getTime()) {
        if (pos1.strike > pos2.strike) {
          // e.g., 80-70
          if (pos1.quantity > 0) {
            // +80, -70
            if (pos1.type === 'C') {
              return displayVertical(
                'Bear Call',
                [pos1, pos2],
                pos2.strike,
                pos1.strike
              )
            } else if (pos1.type === 'P') {
              return displayVertical(
                'Bear Put',
                [pos1, pos2],
                pos2.strike,
                pos1.strike
              )
            } else {
              return displayPlain('Unsupproted Vertical', [pos1, pos2])
            }
          } else {
            if (pos1.type === 'C') {
              return displayVertical(
                'Bull Call',
                [pos1, pos2],
                pos1.strike,
                pos2.strike
              )
            } else if (pos1.type === 'P') {
              return displayVertical(
                'Bull Put',
                [pos1, pos2],
                pos1.strike,
                pos2.strike
              )
            } else {
              return displayPlain('Unsupproted Vertical', [pos1, pos2])
            }
          }
        } else {
          if (pos1.quantity > 0) {
            // +80, -70
            if (pos1.type === 'C') {
              return displayVertical(
                'Bull Call',
                [pos1, pos2],
                pos2.strike,
                pos1.strike
              )
            } else if (pos1.type === 'P') {
              return displayVertical(
                'Bull Put',
                [pos1, pos2],
                pos2.strike,
                pos1.strike
              )
            } else {
              return displayPlain('Unsupproted Vertical', [pos1, pos2])
            }
          } else {
            if (pos1.type === 'C') {
              return displayVertical(
                'Bear Call',
                [pos1, pos2],
                pos1.strike,
                pos2.strike
              )
            } else if (pos1.type === 'P') {
              return displayVertical(
                'Bear Put',
                [pos1, pos2],
                pos1.strike,
                pos2.strike
              )
            } else {
              return displayPlain('Unsupproted Vertical', [pos1, pos2])
            }
          }
        }
      } else if (pos1.strike === pos2.strike) {
        return displayPlain('Horizontal', [pos1, pos2])
      } else {
        return displayPlain('Diagonal', [pos1, pos2])
      }
    } else if (positions.length === 3) {
      if (
        parsedPos[0].exp.getTime() === parsedPos[1].exp.getTime() &&
        parsedPos[0].exp.getTime() === parsedPos[2].exp.getTime()
      ) {
        return displayButterfly('Butterfly', parsedPos, parsedPos[1].strike, [
          parsedPos[0].strike,
          parsedPos[2].strike
        ])
      }
    } else {
      return displayPlain('Options, Unsupported', parsedPos)
    }
  }
}
