import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useReducer
} from 'react'
import AWS from 'aws-sdk'
import { Auth } from 'aws-amplify'

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick () {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const initialState = {
  loading: true,
  awsCredentials: null
}

export const AppContext = createContext(initialState)
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'USER_LOGGED_IN':
        return {
          ...state,
          awsCredentials: action.awsCredentials,
          loading: false
        }
      case 'USER_LOGGED_OUT':
        return {
          ...state,
          awsCredentials: null
        }
      default:
        throw new Error()
    }
  }, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
