import React, { Component } from 'react'

import Chart from './Chart'
import moment from 'moment'
import { Button, Tag, Col, Row, Typography, Space } from 'antd'
import { Icon } from '@ant-design/compatible'

import './App.css'

const { Text } = Typography

const Position = props => {
  const { ticker, relativeSymbol, positions, strategy, price, atr } = props

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.ticker)
    }
  }

  const onAddToWatchlist = () => {
    if (props.onAddToWatchlist) {
      props.onAddToWatchlist(props.ticker)
    }
  }

  if (!positions) {
    return (
      <div className='chartBlock'>
        <Chart ticker={ticker} relativeSymbol={relativeSymbol} />
        {props.onAddToWatchlist && (
          <Button type='normal' size='small' onClick={onAddToWatchlist}>
            <Icon type='plus' />
          </Button>
        )}
        <Tag>{ticker}</Tag>
        {props.onDelete && (
          <Button type='danger' size='small' onClick={onDelete}>
            <Icon type='delete' />
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className='chartBlock'>
      <Chart ticker={ticker} relativeSymbol={relativeSymbol} />
      <PositionHeadline {...props} />
      {positions &&
        positions.map(position => (
          <div key={position.Description} style={{ fontSize: '80%' }}>
            {position.Description} x {position.Quantity}
          </div>
        ))}
    </div>
  )
}

export default Position

const getWinLossClass = percentage => {
  return percentage >= 80
    ? 'itm-strong'
    : percentage >= 60
    ? 'itm-ish'
    : percentage <= 20
    ? 'otm-strong'
    : percentage <= 40
    ? 'otm-ish'
    : ' '
}

const getDeltaClass = direction => {
  return direction > 0.5 ? 'bullish' : direction < 0.5 ? 'bearish' : ' '
}

const PositionHeadline = props => {
  const { ticker, strategy, positions, price, atr } = props

  // Calculating derived values from positions
  const diff = strategy.expiration
    ? moment(strategy.expiration).diff(new Date(), 'days')
    : null
  const direction = getStrategyDirection(props)
  const inTheMoneyRatio = getITMRatio(props)

  // Determining text to print
  const priceText = price ? (Math.round(price * 100) / 100).toFixed(2) : '--'

  // Determining css styles based on values
  const expDaysAwayClass =
    diff <= 0
      ? 'exp-expired'
      : diff <= 2
      ? 'exp-very-soon'
      : diff <= 7
      ? 'exp-soon'
      : 'exp-later'
  const expDateClass = diff < 0 ? 'exp-expired' : 'exp-later'

  return (
    <div style={{ padding: '0px 5px 0px 5px' }}>
      <Row style={{ margin: 1 }}>
        <Col span={16} className='position-tag'>
          <Text strong>{ticker}</Text>{' '}
          {strategy && strategy.text && (
            <Text className={getDeltaClass(direction)} strong>
              {strategy.text}
            </Text>
          )}{' '}
          <PositionDirectionIcon direction={direction} />
        </Col>
        <Col span={8} className='position-tag'>
          <Space size='small'>
            <Text strong>{priceText}</Text>
            {atr !== undefined ? (
              <Text className='minor-text'>({atr.toFixed(2)})</Text>
            ) : null}
          </Space>
        </Col>
      </Row>

      {strategy && strategy.expiration && (
        <Row style={{ margin: 1 }}>
          {/* <Col span={diff !== null ? 16 : 24} className="position-tag">
            <Text className={expDateClass}>
              Expiration: {moment(strategy.expiration).format("MMMM DD")}
            </Text>
          </Col> */}
          <Col span={16} className={`position-tag ${expDaysAwayClass}`}>
            {diff > 0 ? (
              <Text className={expDaysAwayClass}>{diff} days away</Text>
            ) : (
              <Text className={expDaysAwayClass}>Expired</Text>
            )}
          </Col>
          <Col span={8} className='position-tag'>
            {inTheMoneyRatio ? (
              <>
                <Space size='small'>
                  <Text
                    className={getWinLossClass(inTheMoneyRatio.ratio)}
                    strong
                  >
                    {inTheMoneyRatio.ratio}%
                  </Text>
                  {inTheMoneyRatio.excess && (
                    <Text className={getWinLossClass(inTheMoneyRatio.ratio)}>
                      ({inTheMoneyRatio.excess >= 0 && '+'}
                      {inTheMoneyRatio.excess})
                    </Text>
                  )}
                </Space>
              </>
            ) : (
              <>
                {/* <Text strong>{priceText}</Text>
              {atr !== undefined ? (
                <Text className="minor-text">({atr.toFixed(2)})</Text>
              ) : null} */}
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  )
}

const getStrategyDirection = ({ strategy, price }) => {
  if (strategy.type.startsWith('vertical')) {
    if (strategy.maxGain > strategy.maxLoss) {
      return 1
    } else {
      return -1
    }
  } else if (strategy.type === 'butterfly') {
    strategy.maxLosses.sort()
    if (price < strategy.maxLosses[0]) {
      return 1
    } else if (price > strategy.maxLosses[1]) {
      return -1
    } else if (price > strategy.maxGain) {
      return (
        (-(price - strategy.maxGain) /
          (strategy.maxLosses[1] - strategy.maxLosses[0])) *
        2
      )
    } else {
      return (
        ((strategy.maxGain - price) /
          (strategy.maxLosses[1] - strategy.maxLosses[0])) *
        2
      )
    }
  } else if (strategy.type === 'call') {
    return 1
  } else if (strategy.type === 'put') {
    return -1
  } else if (strategy.type === 'stock-long') {
    return 1
  } else if (strategy.type === 'stock-short') {
    return 1
  } else {
    return 0
  }
}

const getITMRatio = ({ strategy, positions, price, atr }) => {
  const { maxGain, maxLoss, maxLosses } = strategy
  if (!price) {
    return null
  }

  // excess in # of ATR
  var ratio, excess, clampedRatio

  if (strategy.type.startsWith('vertical')) {
    ratio = (price - maxLoss) / (maxGain - maxLoss)
    clampedRatio = ratio > 1 ? 1 : ratio < 0 ? 0 : ratio

    if (ratio > 1) {
      excess = ((price - maxGain) * Math.sign(maxGain - maxLoss)) / atr
    } else if (ratio < 0) {
      excess = ((price - maxLoss) * Math.sign(maxGain - maxLoss)) / atr
    }

    return {
      ratio: Math.round(clampedRatio * 100, 2),
      excess: excess !== undefined ? Math.round(excess * 100) / 100 : undefined
    }
  } else if (strategy.type === 'butterfly') {
    if (price > strategy.maxGain) {
      ratio =
        (strategy.maxLosses[1] - price) /
        (strategy.maxLosses[1] - strategy.maxGain)
    } else if (price < strategy.maxGain) {
      ratio =
        (strategy.maxLosses[0] - price) /
        (strategy.maxLosses[0] - strategy.maxGain)
    } else {
      ratio = 1
    }
    clampedRatio = ratio > 1 ? 1 : ratio < 0 ? 0 : ratio

    if (ratio < 0) {
      if (price > strategy.maxGain) {
        excess = (maxLosses[1] - price) / atr
      } else {
        excess = (price - maxLosses[0]) / atr
      }
    }

    return {
      ratio: Math.round(clampedRatio * 100, 2),
      excess: excess !== undefined ? Math.round(excess * 100) / 100 : undefined
    }
  } else if (strategy.type === 'call') {
    return null
  } else if (strategy.type === 'put') {
    return null
  } else if (strategy.type === 'stock-long') {
    return null
  } else if (strategy.type === 'stock-short') {
    return null
  } else {
    return null
  }
}

const PositionDirectionIcon = ({ direction }) => {
  if (direction === null || direction.isNan || direction === undefined) {
    return <></>
  }
  if (direction >= 0.5) {
    return <Icon style={{ fontSize: 16, color: 'darkgreen' }} type='rise' />
  } else if (direction <= -0.5) {
    return <Icon style={{ fontSize: 16, color: 'darkred' }} type='fall' />
  } else {
    return <Icon style={{ fontSize: 16 }} type='column-width' />
  }
}
