import React, { Component } from 'react'

import * as Settings from './Settings'

const Chart = ({ ticker, relativeSymbol }) => {
  const currentTimestamp = new Date().getTime()
  const relative = relativeSymbol ? `[ilJ[${relativeSymbol}]]` : ''

  const url = `https://stockcharts.com/c-sc/sc?r=143680&chart=${ticker},uu[350,a]dacayaci[pb20!b50][dg]${relative}&ts=${currentTimestamp}`
  const sanitizedTicker = ticker?.replace('$', '')

  return (
    <a
      rel='noopener noreferrer'
      target='_blank'
      href={`https://www.tradingview.com/chart/?symbol=${sanitizedTicker}`}
    >
      <img alt={`${ticker}`} src={url} />
    </a>
  )
}

export default Chart
