import React, { useContext, useEffect } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { Authenticator, SignUp, Greetings } from 'aws-amplify-react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import DocumentTitle from 'react-document-title'

import awsconfig from './aws-exports' // if you are using Amplify CLI

import Portfolio from './Portfolio'
import Watchlist from './Watchlist'
import { AppContext } from './Context/AppContext'
import {
  SECTORS_SYMBOLS,
  SECTOR_CONSTITUENTS,
  MARKET_OVERVIEW_SYMBOLS
} from './Utils/SymbolLists'
import TopNav from './TopNav'
import './App.css'

Amplify.configure({
  ...awsconfig,
  mandatorySignIn: true
})

const InnerApp = withRouter(({ location, authState, ...props }) => {
  const { dispatch: appDispatch } = useContext(AppContext)

  const onLoggedIn = async () => {
    try {
      const credentials = await Auth.currentCredentials()

      appDispatch({
        type: 'USER_LOGGED_IN',
        awsCredentials: credentials
      })
    } catch (e) {
      if (e !== 'No current user') {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (authState === 'signedIn') {
      onLoggedIn()
    }
  }, [authState])

  if (authState !== 'signedIn') {
    return <></>
  }

  return (
    <DocumentTitle title='One Good Trade v2'>
      <>
        <TopNav />
        <Switch location={location}>
          <Redirect exact from='/' to='/portfolio' />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route
            exact
            path='/watchlist'
            component={() => (
              <Watchlist
                key='watchlist'
                path='/watchlist'
                listName='Watchlist'
                updateListAfterAdd
                showWatchlist
              />
            )}
          />
          <Route
            exact
            path='/sectors'
            component={() => (
              <Watchlist
                key='sectors'
                locked
                presetSymbols={SECTORS_SYMBOLS}
                relativeSymbol='$SPX'
                path='/sectors'
                listName='Sectors'
              />
            )}
          />
          <Route
            path='/sectors/:etf'
            component={({ match }) => (
              <Watchlist
                key={`sectors/${match.params.etf}`}
                presetSymbols={SECTOR_CONSTITUENTS[match.params.etf]}
                relativeSymbol={match.params.etf}
                path={`/sectors/${match.params.etf}`}
                listName={`Sectors - ${match.params.etf}`}
              />
            )}
          />
          <Route
            exact
            path='/market_overview'
            component={() => (
              <Watchlist
                key='market_overview'
                locked
                presetSymbols={MARKET_OVERVIEW_SYMBOLS}
                path='/market_overview'
                listName='Market Overview'
              />
            )}
          />
          <Route
            exact
            path='/penny-spreads'
            component={() => (
              <Watchlist
                key='penny-spreads'
                path='/penny-spreads'
                listName='Penny Spreads'
                presetSymbols={[
                  'AAL',
                  'AEO',
                  'AGCO',
                  'AGI',
                  'AKBA',
                  'ALSK',
                  'AME',
                  'AMZN',
                  'APA',
                  'APH',
                  'APTV',
                  'ARI',
                  'ARMK',
                  'AVT',
                  'BABA',
                  'BAC',
                  'BBD3',
                  'BC',
                  'BCOV',
                  'BHVN',
                  'BKNG',
                  'BMO',
                  'BXMT',
                  'C',
                  'CALA',
                  'CCOI',
                  'CFX',
                  'CHE',
                  'CHTR',
                  'CLH',
                  'CMI',
                  'CNC',
                  'CNHI',
                  'COO',
                  'CRM',
                  'CRWD',
                  'CTVA',
                  'CVLT',
                  'CVM',
                  'CVX1',
                  'DBX',
                  'DHR',
                  'DIS',
                  'DISH',
                  'DKNG',
                  'DTEA',
                  'DXCM',
                  'EBAY',
                  'EBC',
                  'ECPG',
                  'EEM',
                  'EFA',
                  'EMB',
                  'ENVA',
                  'EQX',
                  'ESTA',
                  'EWZ',
                  'FB',
                  'FCX',
                  'FNV',
                  'FXI',
                  'GDX',
                  'GEO',
                  'GILD',
                  'GM',
                  'HASI',
                  'HAYN',
                  'HCC',
                  'HHC',
                  'HMTV',
                  'HON',
                  'HWM',
                  'IBM',
                  'ICLN',
                  'IHRT',
                  'INOV',
                  'INSP',
                  'IRWD',
                  'ISBC',
                  'J',
                  'JAZZ',
                  'KIM',
                  'KSS',
                  'MA',
                  'MAS',
                  'MDT',
                  'MGNI',
                  'MOS',
                  'MRK',
                  'MRNA',
                  'MS',
                  'MSFT',
                  'MTZ',
                  'NEE',
                  'NLSN',
                  'NOK',
                  'NRG',
                  'NUS',
                  'NWG',
                  'OTIS',
                  'OUT',
                  'PAAS',
                  'PBR',
                  'PEB',
                  'PFE1',
                  'PH',
                  'PHX',
                  'PMT',
                  'PPC',
                  'PRAA',
                  'PRO',
                  'PRTS',
                  'PSXP',
                  'PVG',
                  'PVH',
                  'PWR',
                  'QDEL',
                  'RBC',
                  'RCL',
                  'RFP',
                  'RLJ',
                  'RTX',
                  'RXN',
                  'SABR',
                  'SLB',
                  'SPX',
                  'SPY',
                  'STL',
                  'SYNA',
                  'TCOM',
                  'TEL',
                  'TLND',
                  'TR',
                  'TRP',
                  'TSQ',
                  'TUFN',
                  'TWLO',
                  'UBER',
                  'UNIT',
                  'V',
                  'VMW',
                  'VRRM',
                  'VRT',
                  'WCN',
                  'WEC',
                  'WORK',
                  'WSC',
                  'XLE',
                  'XRT',
                  'ZTS',
                  'ZVZZT',
                  'AAPL',
                  'ABBV',
                  'ABMD',
                  'ABNB',
                  'ABT',
                  'ACN',
                  'ACWI',
                  'ADBE',
                  'ADM',
                  'ADP',
                  'AEM',
                  'AEP',
                  'AGG',
                  'AGI',
                  'AGIO',
                  'AIG',
                  'AJG',
                  'AJRD',
                  'ALGN',
                  'ALL',
                  'ALLK',
                  'ALLY',
                  'ALXN',
                  'AMD',
                  'AMED',
                  'AMGN',
                  'AMH',
                  'AMP',
                  'AMT',
                  'AMWL',
                  'AMX',
                  'AMZN',
                  'AON',
                  'AOS',
                  'APA',
                  'APLE',
                  'APLS',
                  'APPS',
                  'APTV',
                  'ARI',
                  'ARMK',
                  'ARWR',
                  'ASAN',
                  'ATH',
                  'AVB',
                  'AVD',
                  'AVGO',
                  'AVLR',
                  'AVTR',
                  'AVYA',
                  'AWK',
                  'AXP',
                  'AXTA',
                  'AZN',
                  'BA',
                  'BABA',
                  'BAC',
                  'BBIO',
                  'BBIO1',
                  'BBY',
                  'BDX',
                  'BEN',
                  'BERY',
                  'BG',
                  'BHC',
                  'BHP',
                  'BKR',
                  'BLFS',
                  'BLK',
                  'BLMN',
                  'BMY',
                  'BNS',
                  'BP',
                  'BPMP',
                  'BRKB',
                  'BTEST',
                  'BTG',
                  'BTI',
                  'BX',
                  'BYND',
                  'C',
                  'CAG',
                  'CAH',
                  'CAKE',
                  'CARR',
                  'CAT',
                  'CB',
                  'CBO',
                  'CBOE',
                  'CBRE',
                  'CBX',
                  'CCL',
                  'CCXI',
                  'CDK',
                  'CDNS',
                  'CERN',
                  'CF',
                  'CFG',
                  'CHD',
                  'CHGG',
                  'CHRW',
                  'CHTR',
                  'CINF',
                  'CL',
                  'CLOV',
                  'CM',
                  'CMCSA',
                  'CME',
                  'CMS',
                  'CNC',
                  'CNST',
                  'COF',
                  'COG',
                  'COP',
                  'COP1',
                  'COST',
                  'CPRT',
                  'CRM',
                  'CRWD',
                  'CSCO',
                  'CSX',
                  'CTVA',
                  'CUBE',
                  'CUZ',
                  'CVLT',
                  'CVS',
                  'CVX',
                  'CYRX',
                  'D',
                  'DASH',
                  'DCI',
                  'DD',
                  'DE',
                  'DGX',
                  'DHI',
                  'DHR',
                  'DIA',
                  'DIS',
                  'DJX',
                  'DKNG',
                  'DOCU',
                  'DOW',
                  'DUK',
                  'DVA',
                  'DVN',
                  'DVY',
                  'DXCM',
                  'EA',
                  'EBAY',
                  'ECL',
                  'ED',
                  'EEM',
                  'EFA',
                  'EFG',
                  'EIDX',
                  'EIX',
                  'EMR',
                  'EOG',
                  'EPD',
                  'EQH',
                  'ET',
                  'ETN',
                  'EW',
                  'EWC',
                  'EWZ',
                  'EXC',
                  'EXPE',
                  'F',
                  'FAF',
                  'FANG',
                  'FAST',
                  'FATE',
                  'FB',
                  'FDX',
                  'FE',
                  'FICO',
                  'FIS',
                  'FISV',
                  'FLT',
                  'FMC',
                  'FNF',
                  'FOX',
                  'FTV1',
                  'FUBO',
                  'FXB',
                  'FXI',
                  'GDRX',
                  'GDX',
                  'GDXJ',
                  'GE',
                  'GIL',
                  'GILD',
                  'GIS',
                  'GL',
                  'GLD',
                  'GM',
                  'GMS',
                  'GOLD',
                  'GOOG',
                  'GOOGL',
                  'GPN',
                  'GS',
                  'GSK',
                  'GSX',
                  'GWW',
                  'HAE',
                  'HAL',
                  'HCA',
                  'HD',
                  'HES',
                  'HFC',
                  'HIG',
                  'HLT',
                  'HON',
                  'HP',
                  'HPP',
                  'HSY',
                  'HYG',
                  'HYLN',
                  'IBM',
                  'IBO',
                  'IBP',
                  'ICLN',
                  'IEFA',
                  'IEMG',
                  'IEX',
                  'IJH',
                  'IJJ',
                  'IJK',
                  'IJR',
                  'IJS',
                  'IJT',
                  'INTC',
                  'IP',
                  'IQV',
                  'IR',
                  'ITW',
                  'IVE',
                  'IVW',
                  'IWM',
                  'IWP',
                  'IYR',
                  'JBHT',
                  'JEF',
                  'JETS',
                  'JNJ',
                  'JPM',
                  'K',
                  'KDP',
                  'KGC',
                  'KL',
                  'KLAC',
                  'KMB',
                  'KMI',
                  'KO',
                  'KR',
                  'LAMR',
                  'LDOS',
                  'LHCG',
                  'LHX',
                  'LIN',
                  'LLY',
                  'LMND',
                  'LMT',
                  'LOW',
                  'LULU',
                  'MA',
                  'MCD',
                  'MCK',
                  'MDT',
                  'MDY',
                  'MET',
                  'MGA',
                  'MGNI',
                  'MKC',
                  'MMM',
                  'MO',
                  'MOH',
                  'MOS',
                  'MP',
                  'MPC',
                  'MPLX',
                  'MPWR',
                  'MRK',
                  'MRO',
                  'MS',
                  'MSFT',
                  'MSI',
                  'MTCH',
                  'MTEM',
                  'MUR',
                  'NBIX',
                  'NCLH',
                  'NDX',
                  'NEE',
                  'NEM',
                  'NET',
                  'NFLX',
                  'NI',
                  'NKE',
                  'NKLA',
                  'NMRK',
                  'NOW',
                  'NSC',
                  'NTLA',
                  'NTNX',
                  'NTR',
                  'NUE',
                  'NVAX',
                  'NVDA',
                  'NVTA',
                  'NWL',
                  'O',
                  'OKE',
                  'OKTA',
                  'ONB',
                  'ORCL',
                  'OTEX',
                  'OTIS',
                  'OXY',
                  'PAGP',
                  'PANW',
                  'PAYX',
                  'PBR',
                  'PCAR',
                  'PD',
                  'PDD',
                  'PEG',
                  'PEN',
                  'PEP',
                  'PFE',
                  'PFE1',
                  'PG',
                  'PGR',
                  'PHG',
                  'PKG',
                  'PLTR',
                  'PM',
                  'PNC',
                  'PPD',
                  'PPG',
                  'PPL',
                  'PRU',
                  'PSX',
                  'PTCT',
                  'PTON',
                  'PWR',
                  'PXD',
                  'PYPL',
                  'QCOM',
                  'QQQ',
                  'QS',
                  'RCL',
                  'RDN',
                  'REGN',
                  'REXR',
                  'RF',
                  'RFP',
                  'RGLD',
                  'RIO',
                  'RJF',
                  'RNG',
                  'ROKU',
                  'ROP',
                  'RTX',
                  'RUT',
                  'RY',
                  'SBE',
                  'SBUX',
                  'SCHW',
                  'SCL',
                  'SHW',
                  'SILK',
                  'SIRI',
                  'SIX',
                  'SLB',
                  'SLV',
                  'SNAP',
                  'SNOW',
                  'SNY',
                  'SO',
                  'SPG',
                  'SPGI',
                  'SPLK',
                  'SPLV',
                  'SPOT',
                  'SPX',
                  'SPY',
                  'SQ',
                  'SRE',
                  'SSRM',
                  'STAG',
                  'STOK',
                  'STT',
                  'SU',
                  'SUI',
                  'SUN',
                  'SWCH',
                  'SYF',
                  'SYK',
                  'SYY',
                  'T',
                  'TD',
                  'TDOC',
                  'TECH',
                  'TFC',
                  'TGT',
                  'TLT',
                  'TMO',
                  'TMUS',
                  'TOT',
                  'TRP',
                  'TRU',
                  'TSCO',
                  'TSLA',
                  'TSM',
                  'TSN',
                  'TSQ',
                  'TT',
                  'TTD',
                  'TVTX',
                  'TWLO',
                  'TWST',
                  'TWTR',
                  'TXG',
                  'TXN',
                  'U',
                  'UAL',
                  'UBER',
                  'UDR',
                  'UL',
                  'UNH',
                  'UNP',
                  'UPS',
                  'URI',
                  'USB',
                  'UTHR',
                  'V',
                  'VAC',
                  'VALE',
                  'VB',
                  'VEU',
                  'VFF',
                  'VIAC',
                  'VICI',
                  'VIX',
                  'VLO',
                  'VMW',
                  'VNQ',
                  'VO',
                  'VOD',
                  'VRM',
                  'VTR',
                  'VTRS',
                  'VWO',
                  'VZ',
                  'WBA',
                  'WDAY',
                  'WELL',
                  'WEX',
                  'WFC',
                  'WM',
                  'WMB',
                  'WMT',
                  'WPM',
                  'WST',
                  'WYNN',
                  'XBI',
                  'XEL',
                  'XL',
                  'XLE',
                  'XLF',
                  'XLI',
                  'XLK',
                  'XLU',
                  'XLV',
                  'XLY',
                  'XOM',
                  'XOP',
                  'XRX',
                  'XSP',
                  'YUM',
                  'YUMC',
                  'Z',
                  'ZBH',
                  'ZG',
                  'ZI',
                  'ZM',
                  'ZNGA'
                ]}
              />
            )}
          />
          <Route
            exact
            path='/weeklies'
            component={() => (
              <Watchlist
                key='weeklies'
                path='/weeklies'
                listName='Weeklies'
                showWeeklies
              />
            )}
          />
          <Route
            exact
            path='/fx'
            component={() => (
              <Watchlist
                key='fx'
                path='/fx'
                listName='Forex'
                presetSymbols={[
                  '$EURUSD',
                  '$USDJPY',
                  '$GBPUSD',
                  '$USDCHF',
                  '$USDCAD',
                  '$AUDUSD',
                  '$NZDUSD',
                  '$AUDJPY',
                  'UUP',
                  'FXY',
                  'FXE',
                  'FXF',
                  'FXB',
                  'FXC',
                  'FXA'
                ]}
              />
            )}
          />
        </Switch>
      </>
    </DocumentTitle>
  )
})

const App = ({ location, ...props }) => {
  return (
    <Authenticator hide={[SignUp, Greetings]}>
      <InnerApp location={location} />
    </Authenticator>
  )
}

export default App
