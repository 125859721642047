import { requestToApi } from 'react-data-fetching'
import * as Settings from '../Settings'
import * as Cheerio from 'cheerio'

export const getNextEarningDate = async symbol => {
  symbol = symbol.toLowerCase()
  var url = 'https://finance.yahoo.com/quote/' + symbol + '?p=' + symbol

  var result = await requestToApi({
    url: url,
    method: 'GET"'
  })

  if (!result || !result.isOK) return 'N/A'

  var $ = Cheerio.load(result.content)

  var el = $('td[data-test="EARNINGS_DATE-value"]')
  if (!el) return 'N/A'

  return el.text()
}

export const getWeeklies = async () => {
  const response = await requestToApi({
    url: `${Settings.API_BASE_URL}weeklies`,
    method: 'GET'
  })

  if (response.isOK) {
    return response.data.results
  }
}
