import React, { useState, useEffect } from 'react'
import { requestToApi } from 'react-data-fetching'

import { Radio, message } from 'antd'

import * as Settings from './Settings'
import TopNav from './TopNav'
import Position from './Position'
import { getStrategyText } from './Utils/PositionParser'

const Portfolio = props => {
  const [positions, setPositions] = useState([])
  const [sortType, setSortType] = useState('expiration')

  useEffect(() => {
    loadPortfolioData()
  }, [])

  const loadCurrentPriceDataIntoPositions = async (
    symbols,
    currentPositions
  ) => {
    const symbolsParam = symbols.join(',')
    const response = await requestToApi({
      url: `${Settings.API_BASE_URL}quote_latest?symbols=${symbolsParam}`,
      method: 'GET'
    })

    console.log(response)

    if (response.isOK) {
      setPositions(
        currentPositions.map(p => {
          return {
            ...p,
            price: response.data.results[p.ticker]?.price,
            atr: response.data.results[p.ticker]?.atr
          }
        })
      )
    }
  }

  const loadPortfolioData = async () => {
    const response = await requestToApi({
      url: `${Settings.API_BASE_URL}portfolio`,
      method: 'GET'
    })

    if (response.isOK) {
      const newPositions = processPortfolioResponse(response.data.results)
      setPositions(newPositions)
      loadCurrentPriceDataIntoPositions(
        newPositions.map(pos => pos.ticker),
        newPositions
      )
    }
  }

  const processPortfolioResponse = results => {
    const posDict = {}

    if (!results) {
      message.warn('Failed to get results.')
      return
    }

    results.forEach(result => {
      const ticker = result.Symbol?.split(' ')[0]
      if (!posDict[ticker]) {
        posDict[ticker] = {
          ticker: ticker,
          positions: []
        }
      }
      posDict[ticker].positions.push(result)
    })

    Object.values(posDict).forEach(posEntry => {
      posEntry.strategy = getStrategyText(posEntry.positions)
    })

    return Object.values(posDict)
  }

  const sortedPositions = positions.concat()
  if (sortType === 'expiration') {
    sortedPositions.sort((a, b) =>
      b.strategy.expiration > a.strategy.expiration
        ? -1
        : b.strategy.expiration < a.strategy.expiration
        ? 1
        : 0
    )
  } else if (sortType === 'alphabetical') {
    sortedPositions.sort((a, b) =>
      b.ticker > a.ticker ? -1 : b.ticker < a.ticker ? 1 : 0
    )
  }

  return (
    <div className='content'>
      <div style={{ float: 'right', marginRight: 15 }}>
        Sort By:{' '}
        <Radio.Group
          value={sortType}
          onChange={event => {
            setSortType(event.target.value)
          }}
        >
          <Radio.Button value='alphabetical'>Symbol Name</Radio.Button>
          <Radio.Button value='expiration'>Expiration Date</Radio.Button>
        </Radio.Group>
      </div>
      <h1>Portfolio</h1>

      <div>
        {sortedPositions ? (
          <div>
            {sortedPositions.map(position => (
              <Position {...position} key={position.ticker} />
            ))}
          </div>
        ) : (
          <h2>No Positions</h2>
        )}
      </div>
    </div>
  )
}

export default Portfolio
