import React, { Component } from "react";

import { Button, Input } from "antd";

export default class AddSymbolBar extends Component {
  state = {
    addSymbolText: ""
  };

  onSymbolInputChange = e => {
    this.setState({
      addSymbolText: e.currentTarget.value
    });
  };

  onAddSymbols = () => {
    if (this.props.onAddSymbols) {
      this.props.onAddSymbols(
        this.state.addSymbolText
          .toUpperCase()
          .split(",")
          .map(t => t.trim())
      );
    }
  };

  render() {
    return (
      <div>
        Add Symbol:{" "}
        <Input
          style={{ width: "20%" }}
          value={this.state.addSymbolText}
          onChange={this.onSymbolInputChange}
        />
        <Button onClick={this.onAddSymbols}>Add</Button>
      </div>
    );
  }
}
