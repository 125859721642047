import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Button, Menu } from 'antd'

import { AppContext } from './Context/AppContext'
import { SECTOR_CONSTITUENTS } from './Utils/SymbolLists'
import './App.css'

const { SubMenu } = Menu

const TopNav = withRouter(({ location, history, ...props }) => {
  const { state: appState, dispatch: appDispatch } = useContext(AppContext)

  const logOut = () => {
    Auth.signOut()
      .then(data =>
        appDispatch({
          type: 'USER_LOGGED_OUT'
        })
      )
      .catch(err => console.log(err))
  }

  const getConstituentMenuItems = () => {
    return Object.keys(SECTOR_CONSTITUENTS).map(k => {
      return (
        <Menu.Item key={`/sectors/${k}`}>
          <NavLink to={`/sectors/${k}`}>{k}</NavLink>
        </Menu.Item>
      )
    })
  }

  return (
    <>
      <Menu
        activeKey={location.pathname}
        selectedKeys={location.pathname}
        mode='horizontal'
      >
        <Menu.Item key='/portfolio'>
          <NavLink to='/portfolio'>Portfolio</NavLink>
        </Menu.Item>
        <Menu.Item key='/watchlist'>
          <NavLink to='/watchlist'>Watchlist</NavLink>
        </Menu.Item>
        <Menu.Item key='/market_overview'>
          <NavLink to='/market_overview'>Market Overview</NavLink>
        </Menu.Item>

        <SubMenu title={<NavLink to='/sectors'>Sectors</NavLink>}>
          {getConstituentMenuItems()}
        </SubMenu>

        <Menu.Item key='/weeklies'>
          <NavLink to='/weeklies'>Weeklies</NavLink>
        </Menu.Item>
        <Menu.Item key='/fx'>
          <NavLink to='/fx'>FX</NavLink>
        </Menu.Item>
        <Menu.Item key='logout'>
          <Button onClick={logOut}>Logout</Button>
        </Menu.Item>
      </Menu>
    </>
  )
})

export default TopNav
