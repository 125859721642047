import React, { Component } from "react";

import { getWeeklies } from "../Utils/WebServices";

import { Button, message } from "antd";

export default class AddWeekliesSymbolsBar extends Component {
  loadWeeklies = async () => {
    const symbols = await getWeeklies();
    if (symbols) {
      message.info("Weeklies loaded.");

      if (this.props.onAddSymbols) {
        this.props.onAddSymbols(symbols, true);
      }
    } else {
      message.warn("Weeklies failed to load.");
    }
  };

  render() {
    return <Button onClick={this.loadWeeklies}>Load Weeklies</Button>;
  }
}
